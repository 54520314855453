import { HashRouter, Routes, Route } from "react-router-dom";
import "aos/dist/aos.css"; // You can also use <link> for styles
import "animate.css";
import "hover.css";

import AOS from "aos";
import "./App.css";
import Home from "./Home/Home";
import Portfolio from "./Portfolio/Portfolio";
import PortfolioList from "./PortfolioList/PortfolioList";
import AboutPage from "./AboutPage/AboutPage";
import Contact from "./Contact/Contact";

AOS.init({
  delay: 400, // values from 0 to 3000, with step 50ms
  duration: 600, // values from 0 to 3000, with step 50ms
  easing: "ease-in-out",
  offset: 160,
  once: true,
});

function App() {
  const val = localStorage.getItem("PP");
  if (!val && false) {
    return <div>श्री कृष्ण</div>
  }
  return (
    <div className="container">
      {/* <RouterProvider router={router} /> */}
      <HashRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about/" element={<AboutPage />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="/portfolio/" element={<PortfolioList />} />
          <Route path="/portfolio/:name" element={<Portfolio />} />
          {/* <Route path="/time-table-manager-privacy" element={<TimeTableManagerPrivacy />} /> */}
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
